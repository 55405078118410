/**
 * Client-side representation of a Deal
 */

import { ValidationModel } from './base/ValidationModel.jsx';
import { DealBuyerAndSupplier } from './BuyerAndSupplier.jsx';
import { DealConditionsAndCategory } from './ConditionsAndCategory.jsx';
import { DealImagesAndVideos } from './ImagesAndVideos.jsx';
import { DealPriceAndCustomOptions } from './PriceAndCustomOptions.jsx';
import { DealProduct } from './Product.jsx';
import { DealSizeChart } from './SizeChart.jsx';
import { DealShipping } from './Shipping.jsx';
import { NormalizedTree } from '../utils/NormalizedTree';
import { sanitizeDate } from './../utils/sanitizedDate';
import { getAttributeValues } from './../utils/getAttributes';

/**
 * Represents a Product and associated items (Custom Options, Images, Inventory, etc) from Magento
 * in a single model for the Buyer's Portal.
 *
 * Provides a self-validation interface and conversion tools.
 * @export
 * @class Deal
 */
export class Deal {
  static get INVALID() {
    return 0;
  }
  static get PARTIAL() {
    return 1;
  }
  static get COMPLETE() {
    return 2;
  }

  constructor(deal = null) {
    this.meta = {
      id: null,
      realId: null,
      isDirty: false,
      status: '',
      isValid: false,
      tmpId: null,
    };

    this.duplicateImages = false;

    this.id = '';
    this.categories = [];

    this.hasSalesHistory = false;

    /** @type {NormalizedTree} */
    this.variations = new NormalizedTree();

    this.customOptions = [];

    /** @type {DealBuyerAndSupplier} */
    this.buyerAndSupplier = new DealBuyerAndSupplier();

    /** @type {DealProduct} */
    this.product = new DealProduct();
    this.conditionsAndCategory = new DealConditionsAndCategory();
    this.priceAndCustomOptions = new DealPriceAndCustomOptions();
    this.imagesAndVideos = new DealImagesAndVideos();

    /**@type {DealSizeChart} */
    this.sizeChart = new DealSizeChart();

    /** @type {DealShipping} */
    this.shipping = new DealShipping();
    // this.inventory = new DealInventory();
    this.localId = null;
    this.productType = {
      type: '',
      subtypeA: '',
      subtypeB: '',
      combination: '',
    };
    this.changes = [];

    // If an existing deal is passed in, populate from this deal.
    if (deal) {
      this.id = deal.meta?.id || deal.id;
      this.meta = { ...deal.meta };
      this.categories = [...deal.categories];
      this.hasSalesHistory = deal.hasSalesHistory;
      this.variations = new NormalizedTree();
      this.customOptions = deal.customOptions || this.customOptions;
      this.variations.nodes = { ...deal.variations?.nodes };
      this.buyerAndSupplier.setMany(deal.buyerAndSupplier.properties);
      this.product.setMany(deal.product.properties);
      this.sizeChart.setMany(deal.sizeChart.properties);
      this.conditionsAndCategory.setMany(deal.conditionsAndCategory.properties);
      this.priceAndCustomOptions.setMany(deal.priceAndCustomOptions.properties);
      this.imagesAndVideos.setMany(deal.imagesAndVideos.properties);
      this.shipping.setMany(deal.shipping.properties);
      this.shipping.hasSalesHistory = deal.hasSalesHistory;
      // this.inventory.setMany(deal.inventory.properties);
      this.localId = deal.id || deal.localId;
      this.productType = deal.productType;
      this.changes = deal.changes ? [...deal.changes] : [];
      this.imagesAndVideos.duplicateImages =
        deal.imagesAndVideos.duplicateImages;
      this.duplicateImages = deal.duplicateImages;
    }
  }

  _forEachValidationModel(fn) {
    for (const keyName of Object.getOwnPropertyNames(this)) {
      if (this[keyName] instanceof ValidationModel) {
        fn(this[keyName]);
      }
    }
  }

  getErrors(model = '') {
    let validity = null;
    if (model) {
      validity = this[model].getValidity;
    } else {
      validity = this.getValidity;
    }
    let required = [];
    let optional = [];
    const messageMatch = /Error Message: (?<error>.+)$/i;
    if (validity.errors.partial) {
      for (const i in validity.errors.partial) {
        const rawMessage = validity.errors.partial[i];
        const message = rawMessage.match(messageMatch)?.groups.error;
        required.push(message);
      }
    }
    if (validity.errors.complete) {
      for (const i in validity.errors.complete) {
        const rawMessage = validity.errors.complete[i];
        const message = rawMessage.match(messageMatch)?.groups.error;
        optional.push(message);
      }
    }
    return {
      required,
      optional,
    };
  }

  static _videoStringToArray(videoString) {
    // Break up video tags into separate array entries.
    if (!videoString) {
      return [];
    }
    const iframeTagMatcher = /<iframe.+?><\/.+?>/g;
    const iframeMatches = videoString.match(iframeTagMatcher) || [];

    return iframeMatches;
  }

  static _videoArrayToString(videoArray) {
    // Turn video URLs into frame embeds.
    return videoArray.join(' ');
  }

  static fromInternalDeal(deal) {
    const newDeal = new Deal(deal);

    newDeal.id = deal.meta?.id || deal.id;
    newDeal.meta = { ...deal.meta };
    newDeal.categories = [...deal.categories];
    newDeal.variations = new NormalizedTree();
    newDeal.variations.nodes = { ...deal.variations?.nodes };
    newDeal.customOptions = deal.customOptions;
    newDeal.buyerAndSupplier.setMany(deal.buyerAndSupplier._properties);
    newDeal.product.setMany(deal.product._properties);
    newDeal.sizeChart.setMany(deal.sizeChart._properties);
    newDeal.priceAndCustomOptions.setMany(
      deal.priceAndCustomOptions._properties
    );
    newDeal.shipping.setMany(deal.shipping._properties);
    // newDeal.inventory.setMany(deal.inventory._properties);
    newDeal.conditionsAndCategory.setMany(
      deal.conditionsAndCategory._properties
    );
    newDeal.imagesAndVideos.setMany(deal.imagesAndVideos._properties);
    newDeal.productType = deal.productType;
    newDeal.changes = deal.changes ? [...deal.changes] : [];

    if (typeof newDeal.imagesAndVideos.videos === 'string') {
      Deal._videoStringToArray(newDeal.imagesAndVideos.videos);
    }

    newDeal._forEachValidationModel(model => {
      model.clearDirtyFlag();
    });

    return newDeal;
  }

  mergeWithExisting(deal) {
    this.id = deal.meta?.id || deal.id;
    this.meta = { ...deal.meta };
    this.categories = [...deal.categories];
    this.variations = new NormalizedTree();
    this.variations.nodes = { ...deal.variations?.nodes };
    this.buyerAndSupplier.setMany(deal.buyerAndSupplier._properties);
    this.product.setMany(deal.product._properties);
    this.sizeChart.setMany(deal.sizeChart._properties);
    this.priceAndCustomOptions.setMany(deal.priceAndCustomOptions._properties);
    this.shipping.setMany(deal.shipping._properties);
    // this.inventory.setMany(deal.inventory._properties);
    this.conditionsAndCategory.setMany(deal.conditionsAndCategory._properties);
    this.imagesAndVideos.setMany(deal.imagesAndVideos._properties);
    this.productType = deal.productType;
    this.changes = deal.changes ? [...deal.changes] : [];

    this._forEachValidationModel(model => {
      model.clearDirtyFlag();
    });
  }

  static async fromGraphQLDealWithAttributes(deal) {
    const result = Deal.fromGraphQLDeal(deal);

    return result;
  }

  /**
   * Convert a GraphQL Product into a Buyer's Portal Deal model.
   *
   * @param {Object} deal - raw JSON deal result from the GraphQL backend
   * @returns Deal
   */
  static fromGraphQLDeal(deal) {
    const newDeal = new Deal();

    newDeal.id = deal.meta?.id || deal.id;
    newDeal.meta.id = deal.id;
    newDeal.meta.realId = deal.realId;
    newDeal.meta.isValid = deal.meta?.isValid;
    newDeal.hasSalesHistory = deal.hasSalesHistory;
    newDeal.meta.status = deal.meta?.status;
    newDeal.meta.isDirty = deal.meta?.isDirty || true;
    newDeal.product.setMany(deal);
    newDeal.sizeChart.setMany(deal);
    newDeal.buyerAndSupplier.setMany(deal);
    if (!newDeal.buyerAndSupplier.platform) {
      newDeal.buyerAndSupplier.platform = ['WEBSITE'];
    }
    newDeal.priceAndCustomOptions.setMany(deal);
    newDeal.shipping.setMany(deal);
    // newDeal.inventory.setMany(deal);
    newDeal.conditionsAndCategory.setMany(deal);
    newDeal.imagesAndVideos.setMany(deal);
    newDeal.productType = deal.productType;
    newDeal.variations = new NormalizedTree();
    newDeal.variations.nodes = deal.variations?.nodes;
    newDeal.categories = deal.categories ? [...deal.categories] : [];
    newDeal.changes = deal.changes ? [...deal.changes] : [];

    newDeal.imagesAndVideos.videos = Deal._videoStringToArray(
      newDeal.imagesAndVideos.videos
    );

    newDeal._forEachValidationModel(model => {
      model.clearDirtyFlag();
    });

    return newDeal;
  }

  /**
   * Function that hydrates certain fields by performing lookups on the GraphQL backend
   * for certain enum fields.
   *
   * @returns Object
   */
  async toGraphQLDeal() {
    let results = this.toFlatDeal();

    results.adminCost = this.priceAndCustomOptions.adminCost;

    // this is necessary for now FIXME: get this integrated properly
    const attributeSets = await getAttributeValues('AttributeSetEnum');
    if (attributeSets.length > 1) {
      results.attributeSet = attributeSets[1].key;
    }

    return results;
  }

  /** Maps out and sanitizes/casts certain fields to expected values for ODO GraphQL mutations
   *	@returns Object
   */
  toFlatDeal() {
    let results = {};
    this._forEachValidationModel(model => {
      results = { ...results, ...model._properties };
    });
    results.validations = this.validations;
    results.modified = this.modified;
    results.meta = this.meta;

    results.type = 'SIMPLE';
    delete results.hasCustomOptions;

    if (results.features instanceof Array) {
      results.features = results.features.join('|');
    }

    results.cost = +results.cost;
    results.retail = +results.retail;
    results.surcharge = +results.surcharge;
    results.rebateDiscount = +results.rebateDiscount;
    results.price = +results.price;
    results.taxClass = results.taxClass?.id || results.taxClass;
    results.area = results.area?.text || results.area;
    results.condition = results.condition?.id || results.condition;
    results.buyer = results.buyer?.id || results.buyer;
    results.salesAssistant =
      results.salesAssistant?.id || results.salesAssistant;
    results.supplier = results.supplier?.id || results.supplier?.toString();

    // Strip trailing ID number out of format "Supplier Name: (ID:###...#)"
    if (typeof results.supplier === 'string') {
      results.supplier = results.supplier.replace(/ \(ID: \d+\)$/, '');
    }

    results.supplierRepacks =
      results.supplierRepacks?.id || results.supplierRepacks;
    if (Array.isArray(this.conditionsAndCategory?.category)) {
      results.categories = this.conditionsAndCategory.category.map(
        categoryId => +categoryId
      );
    } else {
      results.categories = [];
    }

    if (results.shop?.id) {
      results.categories.push(+results.shop.id);
    }

    if (Array.isArray(results.videos)) {
      results.videos = Deal._videoArrayToString(results.videos);
    }
    results.originalStock = +results.originalStock;
    results.isAlcoholic = !!results.isAlcoholic;
    results.isHygienic = !!results.isHygienic;
    results.isParallelImport = !!results.isParallelImport;
    results.isReferable = !!results.isReferable;
    results.isFragile = !!results.isFragile;
    results.isMainDeal = !!results.isMainDeal;
    results.isNotStaffPurchase = !!results.isNotStaffPurchase;
    results.isSampleReceived = !!results.isSampleReceived;
    results.isPhotographedByStudio = !!results.isPhotographedByStudio;

    if (results.campaign === 'LUNCHTIME_DEAL') {
      results.isLunchtimeProduct = true;
    }

    // if(!results.campaign || results.campaign === "NONE") {
    // 	results.campaign = null;
    // }

    if (results.campaignMailer) {
      results.campaignMailer = results.campaignMailer.filter(x => x);
    }

    if (
      results.campaignMailer &&
      results.campaignMailer.includes('LUNCHTIME_DEAL')
    ) {
      results.isInLunchtimeProductMailer = true;
    }

    // if(typeof results.supplier === "string" && results.supplier.match(/.+\(ID: (\d+)\)/)) {
    // 	const match = results.supplier.match(/.+\(ID: (\d+)\)/);
    // 	if(match.length > 0) {
    // 		results.supplier = match[1];
    // 	}
    // }

    results.width = +results.width;
    results.length = +results.length;
    results.height = +results.height;
    results.weight = +results.weight;
    results.qty = +results.qty;
    if (
      results.shippingCost !== null &&
      results.shippingCost !== undefined &&
      results.shippingCost !== ''
    ) {
      results.shippingCost = +results.shippingCost;
    } else {
      results.shippingCost = null;
    }

    if (results.platform === 'Both') results.platform = 'WEBSITE';
    if (results.supplierRepacks === 'not required')
      results.supplierRepacks = 'NONE';
    results.priority = +results.priority;

    // These values are omitted as they don't exist on the schema,
    // but are used internally to map out some composite fields (i.e.: Categories)
    // and to calculate runtime values.
    delete results.buyersAssistant; // @deprecated?
    delete results.shop;
    delete results.category;
    delete results.hasShop;
    delete results.clearanceSale;
    delete results.soh;
    delete results.vat;
    delete results.insurance;
    delete results.insuranceValue;
    delete results.insuranceOverride;
    delete results.images;
    delete results.qtyOutOfStockThreshold;
    delete results.qtyUsesDecimals;
    delete results.backorders;
    delete results.notifyForQuantity;
    delete results.qtyIncrements;
    delete results.xQuantityLeft;
    delete results.meta;
    delete results.available; // @deprecated?
    delete results.preview;

    // ensuring that there's no extra data on any surcharges (mostly for omitting __typename)
    if (Array.isArray(results.surcharges)) {
      results.surcharges = results.surcharges.map(({ key, value }) => ({
        key,
        value,
      }));
    }

    results.inventory = {
      qty: results.qty,
      minSaleQuantity: +results.minSaleQuantity,
      maximumSaleQuantity: +results.maximumSaleQuantity,
      useConfigMinSaleQty: results.useConfigMinSaleQty,
      useConfigMaxSaleQty: results.useConfigMaxSaleQty,
      isInStock: results.isInStock,
      isApplyMaxSaleQtyToProductOptions:
        results.isApplyMaxSaleQtyToProductOptions,
      isApplyMaxSaleQtyCustomerProfile:
        results.isApplyMaxSaleQtyCustomerProfile,
    };

    // Omit more values from request
    delete results.qty;
    delete results.manage;
    delete results.minSaleQuantity;
    delete results.maxSaleQuantity;
    delete results.maximumSaleQuantity;
    delete results.useConfigMaxSaleQty;
    delete results.useConfigMinSaleQty;
    delete results.isInStock;
    delete results.minQtyInShoppingCart;
    delete results.maxQtyInShoppingCart;

    delete results.isApplyMaxSaleQtyToProductOptions;
    delete results.isApplyMaxSaleQtyCustomerProfile;

    // Not catered for by portal?
    // TODO: (Future consideration) Should probably be added to the portal in a subsequent phase - these fields are required but brittle because of hard-coded values.
    results.visibility = 4;
    results.shortDescription = '';

    results.activeFromDate = sanitizeDate(new Date(results.activeFromDate));
    results.activeToDate = sanitizeDate(new Date(results.activeToDate));

    return results;
  }

  /**
   * Get the current validity status of a Deal.
   *
   * @readonly
   * @memberof Deal
   * @returns ValidityState
   */
  get getValidity() {
    let status = 'complete';
    let errors = [];

    this._forEachValidationModel(model => {
      const v = model.getValidity;
      if (v.status === 'partial' && status !== '') status = 'partial';
      if (v.status === '') status = '';

      for (const key of Object.getOwnPropertyNames(v.errors)) {
        if (!errors[key]) {
          errors[key] = [];
        }
        errors[key] = [...errors[key], ...v.errors[key]];
      }
    });

    return {
      isValid: !!status,
      status,
      errors,
    };
  }

  get properties() {
    let result = {};
    this._forEachValidationModel(model => {
      result = { ...result, ...model.properties };
    });

    result.id = this.meta.id;
    result.realId = this.meta.realId;

    this.meta.isDirty = this.isDirty;

    return result;
  }

  get isDirty() {
    let dirty = false;

    this._forEachValidationModel(model => {
      dirty |= model.isDirty;
    });

    this.meta.isDirty = dirty || this.meta.isDirty;

    return this.meta.isDirty;
  }

  set(model, field, value) {
    let originalValue = null;
    if (this[model]) {
      originalValue = this[model][field];
      this[model].set(field, value);
      //this.meta.isDirty = 1;
    }

    // check for any previous changes to this field and remove them
    const lastChangeIndex = this.changes.findIndex(
      change => change.field === field
    );
    if (lastChangeIndex !== -1) {
      originalValue = this.changes[lastChangeIndex].from;
      this.changes.splice(lastChangeIndex, 1);
    }
    // if the value is the same as the original, don't add a change
    if (originalValue === value) return;
    // add a change entry
    this.changes.push({ model, field, from: originalValue, to: value });
  }

  clearChanges() {
    this.changes = [];
  }

  canUndo(model = '') {
    let filteredList = model
      ? this.changes.filter(c => c.model === model)
      : this.changes;
    return filteredList.length > 0;
  }

  undo(model = '') {
    let filteredList = model
      ? this.changes.filter(c => c.model === model)
      : [...this.changes];
    if (this.canUndo(model)) {
      const change = filteredList.pop();
      const changeIndex = this.changes.findIndex(x => x === change);
      this[change.model].set(change.field, change.from);
      this.changes.splice(changeIndex, 1);
    }
  }

  applyChangesTo(deal) {
    this.changes.forEach(change => {
      deal.set(change.model, change.field, change.to);
    });
  }

  undoAll(model = '') {
    // Undo entire set of changes
    while (this.canUndo(model)) {
      this.undo(model);
    }
  }
}

Deal.MODELS = {
  BUYER_AND_SUPPLIER: 'buyerAndSupplier',
  CONDITIONS_AND_CATEGORY: 'conditionsAndCategory',
  PRICE_AND_CUSTOM_OPTIONS: 'priceAndCustomOptions',
  PRODUCT: 'product',
  IMAGES_AND_VIDEOS: 'imagesAndVideos',
  SHIPPING: 'shipping',
  INVENTORY: 'inventory',
  SIZE_CHART: 'sizeChart',
};

import type { EditorProductInterface } from '@odo/types/portal';
import type { Attribute } from '@odo/contexts/attributes';
import { AttributeCode } from '@odo/types/api';
import { dateString } from '@odo/utils/date';
import { getUserInfo } from '@odo/utils/user';

const draftDefaults = (
  product: EditorProductInterface,
  attributes: Attribute[]
) => {
  product.isNew = true;

  const now = dateString(new Date());
  // add draft metadata (created, updated, etc.)
  product.draft = {
    createdAt: now,
    updatedAt: now,
    duplicatedDealId: product.id,
    createdBy: getUserInfo()?.email,
  };

  // delete ID after setting it to our metadata
  delete product.id;

  // set the default from/to times (dates should be set manually)
  product.activeFromTime = '00:00';
  product.activeToTime = '23:59';

  // biz has requested that this always be true (BP-852)
  product.isReturnableToSupplier = true;

  product.platform = attributes
    ?.find(attr => attr.id === AttributeCode.platform)
    ?.options.map(option => ({ id: option.value, label: option.label }));

  const priority50 = attributes
    ?.find(attr => attr.id === AttributeCode.priority)
    ?.options.find(option => option.key === '50');

  product.priority = priority50
    ? {
        id: parseInt(priority50.originalData.value, 10),
        label: priority50.label,
      }
    : undefined;

  const campaignNone = (
    attributes?.find(attr => attr.id === AttributeCode.campaign)?.options || []
  ).find(({ key }) => key.toLowerCase() === 'none');
  if (campaignNone) {
    product.campaign = {
      id: campaignNone.value,
      label: campaignNone.label,
    };
  }

  const buyInStockNone = (
    attributes?.find(attr => attr.id === AttributeCode.buyInStockType)
      ?.options || []
  ).find(({ key }) => key.toLowerCase() === 'none');
  if (buyInStockNone) {
    product.buyInStockType = {
      id: buyInStockNone.value,
      label: buyInStockNone.label,
    };
  }

  return product;
};

export default draftDefaults;

import styled from '@odo/lib/styled';
import { Input } from '@odo/components/elements/form-fields';
import { Grid } from '@odo/components/elements/layout/grid';
import { Flex } from '@odo/components/elements/layout/flex';

export const FormFilter = styled.form`
  display: contents;
`;

export const FilterWrapper = styled(Grid)`
  gap: 16px;
`;

FilterWrapper.defaultProps = {
  gridTemplateColumns: ['repeat(2, 1fr)', 'repeat(3, 1fr)', 'repeat(4, 1fr)'],
};

export const FilterBox = styled.div<{ disabled?: boolean }>`
  display: block;
  border: 1px solid #c2c2c2;
  border-radius: 4px;
  -ms-overflow-style: none;
  background-color: var(
    ${props => (props.disabled ? '--background-secondary' : '--text-quinary')}
  );
  ::-webkit-scrollbar {
    display: none;
  }
`;

export const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 8px;
  border-bottom: 1px solid #c2c2c2;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  > span {
    font-weight: bold;
    line-height: 14px;
  }
`;

export const CheckboxWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  > span {
    line-height: 14px;
  }
  > .checkbox {
    margin-top: 0;
    margin-bottom: 0;
  }
`;

export const FilterContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  padding: 8px;
  gap: 8px;
  border-bottom-right-radius: 4px;
  border-bottom-left-radius: 4px;
`;

export const FilterMultiSelectContent = styled(Flex)`
  flex-wrap: nowrap;
  flex: 1 1 auto;
`;

export const FilterInput = styled(Input)`
  padding: 5px;
  font-size: 12px;
  text-indent: 2px;
  max-width: 185px;
  &.short-input {
    @media only screen and (min-width: 320px) {
      max-width: 90px;
    }
    @media only screen and (min-width: 1024px) {
      max-width: 100px;
    }
  }
`;

export const SelectFilter = styled.select<{ disabled?: boolean }>`
  padding: 5px;
  border: 1px solid #c2c2c2;
  color: var(--text-secondary);
  background: var(--text-secondary);
  background-color: var(
    ${props => (props.disabled ? '--background-secondary' : '--text-quinary')}
  );
  font-size: 12px;
  border-radius: 4px;
  outline: none;
  width: 100%;
  letter-spacing: 0.02em;
  transition: all 0.2s;
  appearance: none;
  &:focus,
  &:focus-visible {
    border-color: var(--brand-primary);
  }
  &:disabled {
    cursor: not-allowed;
  }
  &.custom-arrow {
    appearance: none;
    background: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 100 100' fill='%23999999'><path d='M0 0h100L50 50z'/></svg>");
    background-size: 10px;
    background-position: calc(100% - 10px) 11px;
    background-repeat: no-repeat;
    outline: none;
  }
`;

import type {
  ApiCustomOption,
  ApiCustomOptionValue,
  ApiProduct,
  ProductInventory,
} from '@odo/types/api';
import type { ReactNode } from 'react';
import { useCallback, useEffect, useMemo, useState, memo } from 'react';
import CustomOptionsEditorContext from './context';
import { actionReducerApply } from './reducer/apply';
import type { Action, ProductMeta } from './types';
import { ActionTypeEnum } from './types';
import save from '@odo/data/custom-options/save-custom-options';
import { useProduct, useSetCustomOptions } from '@odo/contexts/product';
import prepCustomOptionTree from '@odo/data/custom-options/prep-custom-option-tree';
import uuid, { isNewId } from '@odo/utils/uuid';
import type {
  CustomOptionTree,
  CustomOptionTreeValue,
} from '@odo/types/portal';
import {
  loadActionList as loadActionListFromCache,
  persistActionList as persistActionListToCache,
  removeActionList as removeActionListFromCache,
} from '@odo/data/custom-options/cache';
import { copyCustomOptionTree } from '@odo/data/custom-options/copy-custom-option-tree';
import {
  getOptionFieldError,
  getValueFieldError,
  getCumulativeQty as getCumulativeQtyInternal,
} from '@odo/data/custom-options/utils';
import toast from 'react-hot-toast';
import { useDealId } from '@odo/screens/deal/editor/hooks';

const cloneRecursive = (variable: unknown) => {
  if (variable === null) {
    return variable;
  } else if (Array.isArray(variable)) {
    return [...variable.map(v => cloneRecursive(v))];
  } else if (typeof variable === 'object') {
    const newObject = {};
    Object.entries(variable).forEach(([field, value]) => {
      newObject[field] = cloneRecursive(value);
    });
    return newObject;
  } else {
    return variable;
  }
};

const optionHasErrors = (option: CustomOptionTree) =>
  !!getOptionFieldError(option, 'title');

const valueHasErrors = (value: CustomOptionTreeValue) =>
  !!getValueFieldError(value, 'title') || !!getValueFieldError(value, 'sku');

const hasErrorsRecursive = (option: CustomOptionTree) => {
  if (
    optionHasErrors(option) ||
    option.values.some(
      value =>
        valueHasErrors(value) || value.childOptions.some(hasErrorsRecursive)
    )
  ) {
    return true;
  }

  return false;
};

interface CustomOptionsEditorProviderProps {
  children: ReactNode;
  /**
   * This flag is used to enable loading cached data using a different ID type when in the new deal editor.
   */
  newDealEditor?: boolean;
}

const CustomOptionsEditorProvider = ({
  children,
  newDealEditor,
}: CustomOptionsEditorProviderProps) => {
  const { isDraft } = useDealId();

  const product = useProduct();
  const setCustomOptions = useSetCustomOptions();

  const [isSaving, setIsSaving] = useState(false);
  const [showErrors, setShowErrors] = useState(false);
  const [actionList, setActionList] = useState<Action[]>([]);
  const [actionOffset, setActionOffset] = useState<number>(0);
  const [savingOffset, setSavingOffset] = useState<number | undefined>();
  const [copyingOption, setCopyingOption] = useState<
    CustomOptionTree | undefined
  >();

  const [autoSumEnabled, setAutoSumEnabled] = useState(true);

  const productMeta: ProductMeta | undefined = useMemo(
    () =>
      product?.id || product?.product
        ? {
            // NOTE: if for some reason there's no id in the product context, we're gonna generate one here
            ...(product?.id ? { id: product.id } : { id: uuid() }),
            ...(product?.product
              ? {
                  stockId: product.product?.inventory?.id,
                  price: product.product.price,
                  cost: product.product.cost,
                  qty: product.product?.inventory?.qty,
                }
              : {}),
          }
        : undefined,
    [product?.id, product?.product]
  );

  const editorCustomOptions = useMemo(() => {
    const apiCustomOptions = product?.customOptions || [];

    // NOTE: for applying actions we need to be able to edit the options in place
    // but we don't want to be editing the original data returned by the API
    // as that must be re-used from scratch each time actions need to be applied
    const customOptions: ApiCustomOption[] = cloneRecursive(apiCustomOptions);

    actionList.slice(0, actionList.length + actionOffset).forEach(action => {
      try {
        if (action.type in actionReducerApply) {
          actionReducerApply[action.type]({
            action,
            customOptions,
          });
        }
      } catch (e) {
        console.error(e);
        console.warn('Failed to apply action');
        console.warn({ action });
      }
    });

    return prepCustomOptionTree(customOptions);
  }, [product?.customOptions, actionList, actionOffset]);

  const hasUnsavedActions =
    actionList.length - (savingOffset || 0) + actionOffset > 0;

  const canSave = hasUnsavedActions || autoSumEnabled;

  const addAction = useCallback(
    (action: Action) => {
      setActionList(actionList => [
        ...actionList.slice(0, actionList.length + actionOffset),
        action,
      ]);
      setActionOffset(0);
    },
    [actionOffset]
  );

  // -1 to go back +1 to go forward, can move multiple steps in each direction technically, but we won't expose that
  const moveActionOffset = useCallback(
    (offset: number) => setActionOffset(index => Math.min(index + offset, 0)),
    []
  );

  const clearActions = useCallback(() => {
    setActionList([]);
    setActionOffset(0);
    setShowErrors(false);
    setCopyingOption(undefined);

    if (productMeta?.id) {
      removeActionListFromCache({ productId: productMeta.id });
    }
  }, [productMeta?.id]);

  const copyOption = (option: CustomOptionTree) => setCopyingOption(option);

  const cancelCopy = () => setCopyingOption(undefined);

  const highestSortOrder = useMemo(() => {
    let highest = 0;
    editorCustomOptions.forEach(({ sortOrder }) => {
      if (sortOrder && sortOrder > highest) {
        highest = sortOrder;
      }
    });
    return highest;
  }, [editorCustomOptions]);

  const pasteOption = useCallback(
    (parentValueId?: ApiCustomOptionValue['valueId']) => {
      if (
        typeof copyingOption !== 'undefined' &&
        typeof productMeta !== 'undefined' &&
        typeof productMeta.id !== 'undefined'
      ) {
        const { rootOption, childOptions } = copyCustomOptionTree({
          customOption: copyingOption,
          rootOptionSortOrder: highestSortOrder + 1,
        });
        addAction({
          type: ActionTypeEnum.PasteOptions,
          productId: productMeta.id,
          options: [
            {
              ...rootOption,
              parentValueId,
            },
            ...childOptions,
          ],
        });
        cancelCopy();
      }
    },
    [addAction, productMeta, copyingOption, highestSortOrder]
  );

  const validate = useCallback(() => {
    const isValid = !editorCustomOptions.some(hasErrorsRecursive);

    setShowErrors(!isValid);

    return isValid;
  }, [editorCustomOptions]);

  const saveActions = useCallback(
    ({
      productId,
    }: {
      productId: ApiProduct['id'];
      stockId?: ProductInventory['id'];
      latestQty?: ProductInventory['qty'];
    }) => {
      setIsSaving(true);
      setSavingOffset(actionList.length + actionOffset);

      // NOTE: we certainly hope this should never happen.
      // the way it's typed should prevent it, but just in case.
      if (!productId) {
        toast.error(
          'Aborted custom option save coz we failed to pass the product ID through. Please reload the page and try save custom options again. And regardless, please let dev know that you saw this error.'
        );
        return;
      }

      return save({
        productId,
        actionList: actionList.slice(0, actionList.length + actionOffset),
        autoSumEnabled,
        onCompleteCallback: ({
          customOptions,
        }: {
          customOptions?: ApiCustomOption[];
        }) => {
          setIsSaving(false);
          setSavingOffset(undefined);

          if (customOptions) {
            setCustomOptions(customOptions);
          }

          setActionList(list => [
            ...list.slice(actionList.length + actionOffset),
          ]);
        },
      });
    },
    [actionList, actionOffset, autoSumEnabled, setCustomOptions]
  );

  const getCumulativeQty = useCallback(
    (valueQtyLatest?: Record<ApiCustomOptionValue['valueId'], number>) =>
      getCumulativeQtyInternal(
        editorCustomOptions,
        autoSumEnabled,
        [],
        valueQtyLatest
      ),
    [editorCustomOptions, autoSumEnabled]
  );

  /**
   * Load from cache.
   */
  useEffect(() => {
    if (
      productMeta?.id &&
      (isNewId(productMeta.id) || (newDealEditor && isDraft))
    ) {
      const customOptionsCacheProduct = loadActionListFromCache({
        productId: productMeta.id,
      });
      if (customOptionsCacheProduct) {
        setActionList(customOptionsCacheProduct.actionList);
        setActionOffset(customOptionsCacheProduct.actionOffset);
        if (typeof customOptionsCacheProduct.autoSumEnabled !== 'undefined') {
          setAutoSumEnabled(customOptionsCacheProduct.autoSumEnabled);
        }
      }
    }
  }, [productMeta?.id, newDealEditor, isDraft]);

  /**
   * Save to cache.
   */
  useEffect(() => {
    if (
      productMeta?.id &&
      (isNewId(productMeta.id) || (newDealEditor && isDraft))
    ) {
      persistActionListToCache({
        productId: productMeta.id,
        actionList,
        actionOffset,
        autoSumEnabled,
      });
    }
  }, [
    productMeta?.id,
    actionList,
    actionOffset,
    autoSumEnabled,
    newDealEditor,
    isDraft,
  ]);

  const value = useMemo(
    () => ({
      autoSumEnabled,
      canUndo: hasUnsavedActions,
      canRedo: actionOffset < 0,
      canSave,
      hasUnsavedActions,
      canClearActions: actionList.length > 0 && !isSaving,
      isSaving,
      showErrors,
      editorCustomOptions,
      copyingOptionId: copyingOption?.id,
      toggleAutoSumEnabled: () => setAutoSumEnabled(enabled => !enabled),
      copyOption,
      pasteOption,
      cancelCopy,
      validate,
      productMeta,
      addAction,
      moveActionOffset,
      saveActions,
      clearActions,
      getCumulativeQty,
    }),
    [
      actionList.length,
      actionOffset,
      addAction,
      autoSumEnabled,
      canSave,
      clearActions,
      copyingOption?.id,
      editorCustomOptions,
      hasUnsavedActions,
      isSaving,
      moveActionOffset,
      pasteOption,
      productMeta,
      saveActions,
      showErrors,
      validate,
      getCumulativeQty,
    ]
  );

  return (
    <CustomOptionsEditorContext.Provider value={value}>
      {children}
    </CustomOptionsEditorContext.Provider>
  );
};

export default memo(CustomOptionsEditorProvider);
